import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { applicationRoutes } from '../../../config/app_routes';
import { getCurrentPath } from '../routes_helpers';

export const useCurrentPath = () => {
  const location = useLocation();
  const routes = useMemo(() => Object.keys(applicationRoutes), [applicationRoutes]);
  const { route: { path }, params, routeKey } = useMemo(() => {
    try {
      return getCurrentPath(routes);
    } catch (e) {
      return {};
    }
  }, [location.pathname]);

  return {
    path,
    params,
    routeKey,
    search: location.search,
    state: location.state
  };
};
