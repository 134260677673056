import { MarketingBanner } from '@barges/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  getDataFromStorage, getXfmFreeSetupMin, isPrerenderMode, matchBannerRoutes
} from '../../../config/util';
import MarketingImage from '../../../project_items/marketing_img/marketing_image';
import env from '../../../config/variables';

import '@barges/scss/lib/title.css';
import '@barges/scss/lib/marketing-banner.css';

import { toCamelCase } from '../../../../common/config/utils';
import { useCurrentPath } from '../../routes/hooks/useCurrentPath';

const {
  DEFAULT_XFM_CREDITS, XFM_PROGRAM_ACTIVE
} = env;

const TopBanner = ({
  userBonusInfos = [], userXfmProgram = null, userId, anonymousUserBonusInfos
}) => {
  const { t } = useTranslation();
  const { routeKey } = useCurrentPath();
  const isXFMActive = () => XFM_PROGRAM_ACTIVE && XFM_PROGRAM_ACTIVE.toLowerCase() === 'true';

  const [showTopBanner, setShowTopBanner] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowTopBanner(true), 100);
  }, []);

  const shouldShowXFMBanner = () => {
    if (isPrerenderMode()) return false;
    if (!isXFMActive()) return false;
    if (userId) {
      return !!userXfmProgram?.credits;
    }
    const wasLoggedIn = !!getDataFromStorage('WAS_LOGIN');
    return !wasLoggedIn;
  };

  const freeMinutesText = () => {
    const minutesCount = getXfmFreeSetupMin();
    const advisorsCount = userXfmProgram?.credits ?? DEFAULT_XFM_CREDITS ?? 3;
    const minutesText = minutesCount > 1 ? t('enjoy_free_minutes.minutes') : t('enjoy_free_minutes.minute');
    const advisorsText = advisorsCount > 1 ? t('enjoy_free_minutes.advisors') : t('enjoy_free_minutes.advisor');

    return (
      <Trans
        i18nKey= "enjoy_free_minutes.enjoy_free_minutes"
        values={ {
          minutesCount, advisorsCount, minutesText, advisorsText
        } }
      />
    );
  };

  const getActiveOffers = () => {
    if (userId) return userBonusInfos;
    if (anonymousUserBonusInfos) return anonymousUserBonusInfos;
    return window.bonus_infos ?? [];
  };

  const generateMarketingBannerArray = () => {
    if (!matchBannerRoutes(routeKey)) return [];
    const activeOffers = getActiveOffers();
    let offers = [];
    if (activeOffers?.length > 0) {
      const offer = toCamelCase(activeOffers[0]);
      offers = [{ title: offer.specialOffer ?? '', subtitle: offer.bonusText  ?? '' }];
    }
    if (shouldShowXFMBanner()) {
      offers.push({ title: freeMinutesText() });
    }
    return offers;
  };

  const offers = generateMarketingBannerArray();

  if (!offers?.length || isPrerenderMode() || !showTopBanner) return null;

  return (
    <MarketingBanner
      offers={ offers }
      offerIcon={ <MarketingImage offers={ offers } /> }
    />
  );
};

TopBanner.propTypes = {
  userBonusInfos: PropTypes.arrayOf(PropTypes.shape({
    specialOffer: PropTypes.string,
    bonusText: PropTypes.string
  })),
  anonymousUserBonusInfos: PropTypes.array,
  userXfmProgram: PropTypes.shape({
    credits: PropTypes.number
  }),
  userId: PropTypes.number
};

TopBanner.defaultProps = {
  userBonusInfos: [],
  anonymousUserBonusInfos: null,
  userXfmProgram: null,
  userId: null
};

export default TopBanner;
