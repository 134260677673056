import React from 'react';
import PropTypes from 'prop-types';
import { PurchaseSvgIcon } from '../sprites';

function CreditCardImg({
  type, className, style, large
}) {
  return (
    <PurchaseSvgIcon
      id={ type.toLowerCase().replace(' ', '_') }
      className={ className }
      style={ style }
      size={ large ? 71 : [] }
    />
  );
}
CreditCardImg.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  large: PropTypes.bool
};

CreditCardImg.defaultProps = {
  className: '',
  style: {},
  type: 'UNKNOWN',
  large: false
};
export default CreditCardImg;
