import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  purchaseErrorText, paymentSourcesTheme, appThemes
} from '../../config/const';
import classes from './classes.module.scss';
import CreditCardImg from '../../add_credit_card_form/credit_card_img';
import ExpandedText from '../../helpers/expanded_text';
import {
  formatCardName, hideEmailAdress, isPaygAndNotEligibleToPayAsYouGo, getAppTheme
} from '../../config/utils';
import { PurchaseSvgIcon } from '../../sprites';
import { applePayIsSupported, iapIsSupported, getAddIconNameForPOWVersion } from '../../purchase_details_common/purchase_details_common_utils';

const paymentKind = {
  payPal: 'pp',
  creditCard: 'cc',
  applePay: 'ap',
  iap: 'iap'
};

const renderErrorText = ({ errorText }) => {
  if (!errorText) return null;
  return (
    <div className={ classes.errorText }>
      { errorText }
    </div>
  );
};

const renderDefaultText = ({ isDefault, t }) => {
  if (!isDefault) return null;
  return (
    <div className={ classes.defaultText }>
      { t('purchase.default') }
    </div>
  );
};

const renderAdditionalTextIfPaypalBillingAgreementEmail = ({
  paypalBillingAgreementEmail, theme
}) => {
  if (paypalBillingAgreementEmail) {
    if (theme === paymentSourcesTheme.wallet) return null;
    return (
      <ExpandedText
        customClasses={ { text: classes.defaultText } }
        ellipsis
        ellipsisTextStyle={ { color: '#7D7D7D', fontWeight: '500', wordBreak: 'break-all' } }
      >
        { hideEmailAdress(paypalBillingAgreementEmail) }
      </ExpandedText>
    );
  }
  return null;
};

const renderAdditionalText = ({
  errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg, errorTextIfNotEligibleToPayAsYouGo
}) => {
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo }) && !errorText) return renderErrorText({ errorText: errorTextIfNotEligibleToPayAsYouGo });
  if (errorText) return renderErrorText({ errorText });
  if (isDefault && (theme === paymentSourcesTheme.wallet || changingSource)) {
    return renderDefaultText({ isDefault, t });
  }
  return renderAdditionalTextIfPaypalBillingAgreementEmail({ paypalBillingAgreementEmail, theme });
};

const renderErrorImg = ({ errorText }) => {
  if (!errorText) return null;
  return (
    <div className={ classes.sourceErrorContainer }>
      <PurchaseSvgIcon
        id="payment_source_error"
        className={ classes.errorImg }
      />
    </div>
  );
};

const renderArrowImg = ({ changingSource }) => {
  if (changingSource) return null;

  return (
    <PurchaseSvgIcon
      id="payment_arrow"
      className={ classes.paymentArrow }
      size={ 50 }
    />
  );
};

const borderColor = ({ isDefault, changingSource, theme }) => {
  if (isDefault && (changingSource
    || theme === paymentSourcesTheme.wallet)) return { border: '1px solid #7FD14A' };
  return { border: '0.5px solid var(--app-border-primary-color)' };
};

const backgroundColor = ({
  eligibleToPayAsYouGo, isPayg, theme, isPaymentOption
}) => {
  const appTheme = getAppTheme();
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo }) && !isPaymentOption && appTheme !== appThemes.dark) return { backgroundColor: '#F7F7F7' };
  return {};
};

const getPaymentMethodBtnClassName = ({ loading }) => {
  if (loading) return classnames(classes.paymentMethodBtn, classes.skeleton);
  return classes.paymentMethodBtn;
};

const onSourceClickHandler = ({
  disableEdit, reauthorizeRequired, expired, errorText, onEditSource, id, options, theme, onMakeDefault,
  managePaymentSource, kind, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
}) =>  {
  if (!disableEdit && (reauthorizeRequired || expired || errorText)
    && errorText !== purchaseErrorText.cannotBeUsedOnThisBrowser) {
    if (errorText === purchaseErrorText.pleaseReEnterTheCardDetailsForSecurityReasons
      && editCardIfSecurityReasons) {
      editCardIfSecurityReasons(id, kind);
      return;
    }
    onEditSource(id);
    return;
  }
  if (theme === paymentSourcesTheme.purchase && isPaygAndNotEligibleToPayAsYouGo({ isPayg, eligibleToPayAsYouGo })) {
    if (isPaymentOption) managePaymentSource(id);
    return;
  }
  if (theme === paymentSourcesTheme.purchase && (options && options.length < 1)) {
    onMakeDefault();
    return;
  }
  managePaymentSource(id);
};

const sourceImgStyle = (changingSource) => (changingSource ? classes.sourceImgChangingSource : classes.sourceImg);

function PaymentMethod({
  isDefault,  reauthorizeRequired, changingSource,
  expired, id, onEditSource, disableEdit, kind, onMakeDefault,
  errorText, context, managePaymentSource, theme, loading, options, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
}) {
  const { t } = useTranslation();
  const { type, last4, paypalBillingAgreementEmail } = context || {};

  const notSupportedPaymentSource = () => (
    <div className={ classes.notSupportedContainer }>
      <span className={ classes.notSupportedOption }>
        {t('purchase.payment_option_not_supported')}
      </span>
      <div
        className={ classes.mobileSource }
        style={ {
          ...borderColor({ isDefault, changingSource, theme }),
          ...backgroundColor({
            eligibleToPayAsYouGo, isPayg, theme, isPaymentOption
          })
        } }
      >
        <div className={ classes.sourceNameContainer }>
          <PurchaseSvgIcon
            id="payment_method_invalid"
            className={ sourceImgStyle(changingSource) }
          />
          <div className={ classes.sourceShortInfo }>
            {t('purchase.change_payment_option')}
          </div>
        </div>
        { renderArrowImg({ theme, changingSource }) }
      </div>
    </div>
  );

  const applePaySource = useMemo(() => (!applePayIsSupported() ? notSupportedPaymentSource() : (
    <div
      className={ classes.mobileSource }
      style={ {
        ...borderColor({ isDefault, changingSource, theme }),
        ...backgroundColor({
          eligibleToPayAsYouGo, isPayg, theme, isPaymentOption
        })
      } }
    >
      <div className={ classes.sourceNameContainer }>
        <PurchaseSvgIcon
          id="apple_pay_icon"
          className={ sourceImgStyle(changingSource) }
        />
        {' '}
        <div className={ classes.sourceShortInfo }>
          Apple Pay
          { renderAdditionalText({
            errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg, errorTextIfNotEligibleToPayAsYouGo: t('purchase.apple_pay_is_not_available_for_this_service')
          }) }
        </div>
      </div>
      { renderArrowImg({ theme, changingSource }) }
    </div>
  )), [isPayg, changingSource, isDefault, t, theme, eligibleToPayAsYouGo, isPaymentOption]);

  const paypalSorce =  (
    <div
      className={ classes.mobileSource }
      style={ {
        ...borderColor({ isDefault, changingSource, theme }),
        ...backgroundColor({
          eligibleToPayAsYouGo, isPayg, theme, isPaymentOption, errorText
        })
      } }
    >
      { renderErrorImg({ errorText }) }
      <div className={ classes.sourceNameContainer }>
        <PurchaseSvgIcon
          id="paypal_icon"
          className={ sourceImgStyle(changingSource) }
        />
        <div className={ classes.sourceShortInfo }>
          Paypal
          { renderAdditionalText({
            errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg, errorTextIfNotEligibleToPayAsYouGo: t('purchase.paypal_is_not_available_for_this_service')
          }) }
        </div>
      </div>
      { renderArrowImg({ theme, changingSource }) }
    </div>
  );

  const creditCardSource = (
    <div
      className={ classes.mobileSource }
      style={ {
        ...borderColor({ isDefault, changingSource, theme }),
        ...backgroundColor({
          eligibleToPayAsYouGo, isPayg, theme, isPaymentOption, errorText
        })
      } }
    >
      { renderErrorImg({ errorText }) }
      <div className={ classes.sourceNameContainer }>
        <CreditCardImg
          className={ sourceImgStyle(changingSource) }
          type={ type }
          large={ theme === paymentSourcesTheme.wallet }
        />
        <div className={ classes.sourceShortInfo }>
          { formatCardName(type) }
          {' * * * '}
          { last4 }
          { renderAdditionalText({
            errorText, changingSource, isDefault, t, paypalBillingAgreementEmail, theme, eligibleToPayAsYouGo, isPayg, errorTextIfNotEligibleToPayAsYouGo: t('purchase.this_card_cannot_be_used_for_zen_mode')
          }) }
        </div>
      </div>
      { renderArrowImg({ theme, changingSource }) }
    </div>
  );

  const iapSource = () => {
    if (!iapIsSupported()) return notSupportedPaymentSource();
    return (
      <div
        className={ classes.mobileSource }
        style={ {
          ...borderColor({ isDefault, changingSource, theme }),
          ...backgroundColor({
            eligibleToPayAsYouGo, isPayg, theme, isPaymentOption, errorText
          })
        } }
      >
        <div className={ classes.sourceNameContainer }>
          <PurchaseSvgIcon
            id={ getAddIconNameForPOWVersion('iap_icon') }
            className={ sourceImgStyle(changingSource) }
          />
          <div className={ classes.sourceShortInfo }>
            { t('purchase.in_app_purchase') }
          </div>
        </div>
        { renderArrowImg(theme, changingSource) }
      </div>
    );
  };

  const renderLoadingContent = () => (<div className={ classes.loadingContent } />);

  const renderContent = () => {
    if (loading) return renderLoadingContent();
    switch (kind) {
      case paymentKind.creditCard: {
        return creditCardSource;
      }
      case paymentKind.payPal: {
        return paypalSorce;
      }
      case paymentKind.applePay: {
        return applePaySource;
      }
      case paymentKind.iap: {
        return iapSource();
      }
      default:
        return null;
    }
  };

  const onSourceClick = () =>  {
    onSourceClickHandler({
      disableEdit, reauthorizeRequired, expired, errorText, onEditSource, id, options, theme, onMakeDefault,
      managePaymentSource, kind, editCardIfSecurityReasons, isPayg, eligibleToPayAsYouGo, isPaymentOption
    });
  };

  const renderPaymentMethod = () => (
    <div className={ classes[`sourceContainer_${ theme }`] }>
      <button type="button" onClick ={ onSourceClick } className={ getPaymentMethodBtnClassName({ loading }) }>
        { renderContent() }
      </button>
    </div>
  );

  return renderPaymentMethod();
}

PaymentMethod.propTypes = {
  id: PropTypes.number,
  kind: PropTypes.string,
  context: PropTypes.object,
  isDefault: PropTypes.bool,
  expired: PropTypes.bool,
  options: PropTypes.array,
  editable: PropTypes.bool,
  onEditSource: PropTypes.func,
  onMakeDefault: PropTypes.func,
  removeSource: PropTypes.func,
  disableEdit: PropTypes.bool,
  managePaymentSource: PropTypes.func.isRequired,
  clickSource: PropTypes.string,
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  loading: PropTypes.bool,
  changingSource: PropTypes.oneOfType(
    [PropTypes.bool, PropTypes.string, PropTypes.number]
  ).isRequired,
  editCardIfSecurityReasons: PropTypes.func,
  isPayg: PropTypes.bool,
  isPaymentOption: PropTypes.bool
};

PaymentMethod.defaultProps = {
  id: null,
  kind: null,
  context: {},
  isDefault: null,
  expired: null,
  options: [],
  editable: true,
  disableEdit: false,
  onEditSource: null,
  onMakeDefault: null,
  removeSource: null,
  clickSource: '',
  theme: paymentSourcesTheme.wallet,
  loading: null,
  changingSource: false,
  editCardIfSecurityReasons: null,
  isPayg: null,
  isPaymentOption: null
};

export default PaymentMethod;
