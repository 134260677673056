import React, { FC, CSSProperties } from 'react';

export interface SvgProps {
  id: string;
  size?: number | number[];
  theme?: string;
  title?: string;
  className?: string;
  style?: CSSProperties;
}

const getSize = (size: number | number[]) => {
  if (typeof size === 'number') return [size, size];
  if (typeof size === 'object' && size.length === 2) return size;
  return [0, 0];
};

export const SvgIcon: FC<SvgProps> = ({
  id, size = 30, title, className, style
}) => {
  const [width, height] = getSize(size);
  return (
    <svg width={ width } height={ height } className={ className } style={ style }>
      {title && <title>{ title }</title>}
      <use href={ `#${ id }` } />
    </svg>
  );
};

export const PurchaseSvgIcon: FC<SvgProps> = ({
  id, size = 30, className, style, title
}) => {
  const [width, height] = getSize(size);

  return (
    <svg width={ width } height={ height } className={ className } style={ style }>
      {title && <title>{ title }</title>}
      <use href={ `#${ id }` } />
    </svg>
  );
};
