/* eslint-disable object-property-newline */
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PaymentSourcesListType } from './payment_sources_prop_types';
import PaymentMethod from './payment_method/payment_method';
import PaymentMethodDetails from './payment_method_details/payment_method_details';
import classes from './classes.module.scss';
import AddCreditCard from './add_credit_card/add_credit_card';
import { paymentSourcesTheme } from '../config/const';
import PaymentMethodsListTitle from './payment_methods_list_title';
import Toast from '../helpers/toast/toast';
import {
  getReturnTo, getForceAddKind, getPaymentMethodsContentContainerStyle, titleDataHandler,
  renderAddSourceForKindHandler, onPaymentMethodSelectedHandler,
  handleDoneAddCardHandler, trackPaymentSourcesEventHandler, onAddSourceHandler, onDefault,
  renderPaymentMethodsContent, getShouldAutoSelect, renderAddCreditCardHandler,
  needRenderOnlyAddCreditCard
} from './payment_methods_list_utils';
import { applePayIsSupported } from '../purchase_details_common/purchase_details_common_utils';

const trackPageVisitHandler = (theme, trackPageVisit, existing) => {
  if (existing && existing.length <= 0) return;
  if (trackPageVisit && theme === paymentSourcesTheme.wallet) {
    trackPageVisit('payment methods');
  }
  if (trackPageVisit && theme === paymentSourcesTheme.purchase) {
    trackPageVisit('use different payment method draw');
  }
};

function PaymentMethodsList({
  addSource, theme, clickSource, hidePopup, startEditingPaymentSource, paymentSources,
  managePaymentSource, onMakeDefault, doneManagingPaymentSource, updateDefault,
  loadAvailable, removeSource, getAddCardCinfig, updatePaymentSource, doneAddCard,
  log, clearAddingCardError, doneChangingPaymentSource, analyticsEvents, changingSource,
  setInAppPaymentOptions, receivedMessageFromApp, setMessageToAppData, showPaymentSources,
  loadPaymentOptions, editCardIfSecurityReasons, isPayg, trackPageVisit
}) {
  const {
    existing, available, addingCardData, managingSource, loading, loaded
  } = paymentSources || {};
  const  {
    trackPaymentSourcesEvent,
    trackStartAddingCreditCard,
    trackCreditCardAdded,
    trackAddCreditCardFailed
  } = analyticsEvents;

  const { t } = useTranslation();
  const [titleData, setTitleData] = useState({});
  const [needTrackPaymentSourcesEvent, setNeedTrackPaymentSourcesEvent] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const returnTo = getReturnTo();
  const forceAddKind = getForceAddKind();
  const shouldAutoSelect = getShouldAutoSelect();

  useEffect(() => {
    loadAvailable(true);
    if (forceAddKind) {
      addSource(forceAddKind);
    }
    trackPageVisitHandler(theme, trackPageVisit, existing);
    return () => {
      doneManagingPaymentSource();
    };
  }, []);

  useEffect(() => {
    trackPaymentSourcesEventHandler({
      loaded, existing, needTrackPaymentSourcesEvent, trackPaymentSourcesEvent, clickSource,
      setNeedTrackPaymentSourcesEvent, theme, loading
    });
  }, [paymentSources, existing]);

  const onAddSource = (kind) => {
    onAddSourceHandler({
      kind, history, addSource, showPaymentSources, location
    });
  };

  const onPaymentMethodSelected = useCallback(() => {
    onPaymentMethodSelectedHandler({
      hidePopup, returnTo, theme, history, location
    });
  }, [hidePopup, returnTo]);

  const renderAddSourceForKind = (kind) => (
    renderAddSourceForKindHandler({
      kind, available, theme, onAddSource, existing, setInAppPaymentOptions, loading, isPayg
    })
  );

  const renderSource = (s) => {
    if (s.kind === 'ap' && !applePayIsSupported()) {
      return null;
    }
    return (
      <PaymentMethod
        key={ s.id }
        onEditSource={ startEditingPaymentSource }
        { ...s }
        managePaymentSource={ managePaymentSource }
        onMakeDefault={ () => onDefault(s, onMakeDefault, history, location, setShowToast, theme, updateDefault, clickSource) }
        clickSource={ clickSource }
        theme={ theme }
        loading={ loading }
        changingSource={ changingSource }
        editCardIfSecurityReasons={ editCardIfSecurityReasons }
        isPayg={ isPayg }
      />
    );
  };
  const renderManagingSource = () => (
    <PaymentMethodDetails
      { ...managingSource }
      existing={ existing }
      onMakeDefault={ () => onDefault(managingSource, onMakeDefault, history, location, setShowToast, theme, updateDefault, clickSource) }
      removeSource={ removeSource }
    />
  );

  const handleDoneAddCard = () => {
    handleDoneAddCardHandler({
      theme, existing, doneChangingPaymentSource, doneAddCard, showPaymentSources
    });
  };

  const renderAddCardBottomBtn = (desktop) => <div className={ desktop ? classes.addCardBottomBtnContainerDesktop : classes.addCardBottomBtnContainer }>{ renderAddSourceForKind('cc') }</div>;

  const renderAddCardHeaderBtn = () => renderAddSourceForKind('cc');

  useEffect(() => {
    titleDataHandler({
      managingSource, setTitleData, doneManagingPaymentSource, doneChangingPaymentSource, theme,
      renderAddCardHeaderBtn, t, shouldAutoSelect, history, location, existing
    });
  }, [managingSource, available, theme]);

  const renderAddCreditCard = () => {
    const { id, config, error } = addingCardData || {};
    const addCreditCard = (
      <AddCreditCard
        id={ id }
        onPaymentMethodSelected={ onPaymentMethodSelected }
        clickSource={ clickSource }
        getAddCardCinfig={ getAddCardCinfig }
        updateCard={ updatePaymentSource }
        trackStartAddingCreditCard={ trackStartAddingCreditCard }
        doneAddCard={ handleDoneAddCard }
        trackCreditCardAdded={ trackCreditCardAdded }
        trackAddCreditCardFailed={ trackAddCreditCardFailed }
        config={ config }
        log={ log }
        error={ error }
        clearAddingCardError={ clearAddingCardError }
        receivedMessageFromApp={ receivedMessageFromApp }
        setMessageToAppData={ setMessageToAppData }
        loadPaymentOptions={ loadPaymentOptions }
        paymentSources={ paymentSources }
        isPayg={ isPayg }
      />
    );
    return renderAddCreditCardHandler({
      addCreditCard, theme, id, changingSource
    });
  };

  const renderPaymentMethodsListTitle = () => (
    <PaymentMethodsListTitle
      titleData={ titleData }
      theme={ theme }
      receivedMessageFromApp={ receivedMessageFromApp }
      setMessageToAppData={ setMessageToAppData }
      loading={ loading }
    />
  );

  const renderToast = () => (
    <Toast
      toastText={ t('purchase.default_payment_updated') }
      show={ showToast }
    />
  );

  const renderPaymentMethodsList = () => {
    if (needRenderOnlyAddCreditCard({ addingCardData, theme, changingSource })) {
      return renderAddCreditCard();
    }
    return (
      <div className={ classes[`root_${ theme }`] }>
        { renderPaymentMethodsListTitle() }
        <div
          className={ classes.paymentMethodsContentContainer }
          style={ { ...getPaymentMethodsContentContainerStyle({ managingSource }) } }
        >
          { renderPaymentMethodsContent({
            managingSource, theme, renderManagingSource, loading, existing,
            renderSource, t, renderAddCardBottomBtn, renderAddSourceForKind, renderAddCreditCard
          }) }
          { renderToast(showToast) }
        </div>
      </div>
    );
  };

  return renderPaymentMethodsList();
}

PaymentMethodsList.propTypes = {
  paymentSources: PropTypes.shape(PaymentSourcesListType),
  theme: PropTypes.oneOf([paymentSourcesTheme.purchase, paymentSourcesTheme.wallet]),
  addSource: PropTypes.func.isRequired,
  hidePopup: PropTypes.func,
  startEditingPaymentSource: PropTypes.func.isRequired,
  managePaymentSource: PropTypes.func.isRequired,
  clickSource: PropTypes.string,
  onMakeDefault: PropTypes.func.isRequired,
  removeSource: PropTypes.func,
  doneManagingPaymentSource: PropTypes.func.isRequired,
  loadAvailable: PropTypes.func,
  getAddCardCinfig: PropTypes.func.isRequired,
  updatePaymentSource: PropTypes.func.isRequired,
  doneAddCard: PropTypes.func.isRequired,
  log: PropTypes.func.isRequired,
  clearAddingCardError: PropTypes.func.isRequired,
  doneChangingPaymentSource: PropTypes.func,
  analyticsEvents: PropTypes.object,
  updateDefault: PropTypes.func,
  changingSource: PropTypes.oneOfType(
    [PropTypes.bool, PropTypes.string, PropTypes.number]
  ).isRequired,
  setInAppPaymentOptions: PropTypes.func,
  receivedMessageFromApp: PropTypes.object,
  setMessageToAppData: PropTypes.func,
  showPaymentSources: PropTypes.func,
  editCardIfSecurityReasons: PropTypes.func.isRequired,
  trackPageVisit: PropTypes.func
};

PaymentMethodsList.defaultProps = {
  theme: paymentSourcesTheme.wallet,
  paymentSources: {},
  clickSource: null,
  hidePopup: null,
  loadAvailable: null,
  doneChangingPaymentSource: null,
  analyticsEvents: {},
  removeSource: null,
  updateDefault: null,
  changingSource: false,
  setInAppPaymentOptions: null,
  receivedMessageFromApp: {},
  setMessageToAppData: null,
  showPaymentSources: null,
  trackPageVisit: null
};

export default PaymentMethodsList;
